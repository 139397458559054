import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { UserComments } from 'views/admin/users/components/ModerationBlock';

import {
  approveUser,
  deletePhoto,
  editBio,
  editCaste,
  editGender,
  fetchUser,
  getUserEvents,
  rejectUser,
  setUserComments,
  setUserUnban,
} from '../shared/api/users';
import { IUser } from '../shared/model';
import { NO_IMAGE } from '../config';
import { IShortOffersList, IUserEventsList } from '../shared/model/offers';
import { fetchShortOffers } from '../shared/api/offers';

export interface IUserStore {
  user: IUser;
  loading: boolean;
  error: boolean;

  shortOffersList: IShortOffersList;
  loadingShortOffersList: boolean;
  errorShortOffersList: boolean;
  acceptRejectLoading: boolean;
  acceptRejectError: boolean;

  commentUpdateLoading: boolean;
  commentUpdateError: boolean;

  photoDeleteLoading: boolean;
  photoDeleteError: boolean;

  bioUpdateLoading: boolean;
  bioUpdateError: boolean;

  casteUpdateLoading: boolean;
  casteUpdateError: boolean;

  genderUpdateLoading: boolean,
  genderUpdateError: boolean,

  getUserEventsLoading: boolean;
  getUserEventsError: boolean;
  userEvents: IUserEventsList;

  unbanUserLoading: boolean;
  unbanUserError: boolean;
}

export interface IUserStoreActions {
  fetchUser: (id: number) => void;
  getAvatar: () => string;

  fetchShortOffersList: (id: number, page: number) => void;
  approveModeration: (id: number) => void;
  rejectModeration: (id: number) => void;
  commentUser: (id: number, comments: UserComments) => void;
  deletePhoto: (id: string) => Promise<void>;
  editBio: (bio: string) => Promise<void>;
  editCaste: (caste: string) => Promise<void>;
  editGender: (gender: string) => Promise<void>;

  getUserEvents: (id: number) => void;
  unbanUser: (id: number) => void;
}

const userStore = create<IUserStore & IUserStoreActions>()(
  devtools(
    immer((set, get) => ({
      user: {} as IUser,
      loading: false,
      error: false,
      acceptRejectLoading: false,
      acceptRejectError: false,
      commentUpdateLoading: false,
      commentUpdateError: false,
      fetchUser: async (id: number) => {
        try {
          set(state => {
            state.loading = true;
          });
          const user = await fetchUser(id);
          set(state => {
            state.user = user;
            state.loading = false;
          });
        } catch (error) {
          set(state => {
            state.error = true;
            state.loading = false;
          });
        }
      },
      getAvatar: () => {
        const userPhotos = get().user?.profile?.photos;
        if (userPhotos) {
          return userPhotos.filter(photo => photo.isCover)?.[0].image ?? NO_IMAGE;
        } else {
          return NO_IMAGE;
        }
      },

      shortOffersList: {} as IShortOffersList,
      loadingShortOffersList: false,
      errorShortOffersList: false,

      fetchShortOffersList: async (id: number, page: number = 1) => {
        try {
          set(state => {
            state.loadingShortOffersList = true;
          });
          const shortOffersList = await fetchShortOffers(id, page);
          set(state => {
            state.shortOffersList = shortOffersList;
            state.loadingShortOffersList = false;
          });
        } catch (error) {
          set(state => {
            state.errorShortOffersList = true;
            state.loadingShortOffersList = false;
          });
        }
      },

      approveModeration: async (id: number) => {
        try {
          set(state => {
            state.acceptRejectLoading = true;
          });

          await approveUser(id);

          set(state => {
            state.acceptRejectLoading = false;
          });

          await get().fetchUser(id);
        } catch (error) {
          set(state => {
            state.acceptRejectError = true;
            state.acceptRejectLoading = false;
          });
        }
      },

      rejectModeration: async (id: number) => {
        try {
          set(state => {
            state.acceptRejectLoading = true;
          });
          await rejectUser(id);

          set(state => {
            state.acceptRejectLoading = false;
          });

          await get().fetchUser(id);
        } catch (error) {
          set(state => {
            state.acceptRejectError = true;
            state.acceptRejectLoading = false;
          });
        }
      },

      commentUser: async (id: number, comments: UserComments) => {
        try {
          set(state => {
            state.commentUpdateLoading = true;
          });
          await setUserComments(id, comments);

          set(state => {
            state.commentUpdateLoading = false;
          });
        } catch (error) {
          set(state => {
            state.acceptRejectError = true;
            state.acceptRejectLoading = false;
          });
        }
      },

      photoDeleteLoading: false,
      photoDeleteError: false,

      deletePhoto: async (id: string) => {
        try {
          set(state => {
            state.photoDeleteLoading = true;
          });
          await deletePhoto(id);

          set(state => {
            state.photoDeleteLoading = false;
          });
        } catch (error) {
          set(state => {
            state.photoDeleteError = true;
            state.photoDeleteLoading = false;
          });
        }
      },

      bioUpdateLoading: false,
      bioUpdateError: false,

      editBio: async (bio: string) => {
        try {
          set(state => {
            state.bioUpdateLoading = true;
            state.bioUpdateError = false;
          });
          await editBio(get().user.userId, bio);

          set(state => {
            state.bioUpdateLoading = false;
          });
        } catch (error) {
          set(state => {
            state.bioUpdateError = true;
            state.bioUpdateLoading = false;
          });
        }
      },
      
      casteUpdateLoading: false,
      casteUpdateError: false,

      editCaste: async (caste: string) => {
        try {
          set(state => {
            state.casteUpdateLoading = true;
            state.casteUpdateError = false;
          });
          await editCaste(get().user.userId, caste);

          set(state => {
            state.casteUpdateLoading = false;
          });
        } catch (error) {
          set(state => {
            state.casteUpdateError = true;
            state.casteUpdateLoading = false;
          });
        }
      },
      
      genderUpdateLoading: false,
      genderUpdateError: false,

      editGender: async (gender: string) => {
        try {
          set(state => {
            state.genderUpdateLoading = true;
            state.genderUpdateError = false;
          });
          await editGender(get().user.userId, gender);

          set(state => {
            state.genderUpdateLoading = false;
          });
        } catch (error) {
          set(state => {
            state.genderUpdateError = true;
            state.genderUpdateLoading = false;
          });
        }
      },

      userEvents: {} as IUserEventsList,
      getUserEventsLoading: false,
      getUserEventsError: false,

      getUserEvents: async (id: number) => {
        try {
          set(state => {
            state.getUserEventsLoading = true;
            state.getUserEventsError = false;
          });
          const events = await getUserEvents(id);

          set(state => {
            state.getUserEventsLoading = false;
            state.userEvents = events;
          });
        } catch (error) {
          set(state => {
            state.getUserEventsError = true;
            state.getUserEventsLoading = false;
          });
        }
      },

      unbanUserLoading: false,
      unbanUserError: false,

      unbanUser: async (id: number) => {
        try {
          set(state => {
            state.unbanUserLoading = true;
            state.unbanUserError = false;
          });
          await setUserUnban(id);

          await fetchUser(id);

          set(state => {
            state.unbanUserLoading = false;
          });
        } catch (error) {
          set(state => {
            state.unbanUserError = true;
            state.unbanUserLoading = false;
          });
        }
      },
    }))
  )
);

export const userLoader = async ({ params }: { params: { id: string } }) => {
  await userStore.getState().fetchUser(parseInt(params.id));

  return userStore.getState().user;
};
export default userStore;
